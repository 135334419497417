import React, { Component } from "react";
import ReactGA from "react-ga";
import { googleLogout } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { INITIAL_USER_DATA } from "common/user";

const UserContext = React.createContext();

class UserContextProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      authState: false,
      isLoading: true,
      login: (value) => this.login(value),
      logout: () => this.logout(),
    };
  }

  componentDidMount() {
    let user = localStorage.getItem("userData");

    if (user) {
      this.oldLog(JSON.parse(user));
    } else {
      this.logout();
    }
  }

  oldLog = (value) => {
    localStorage.setItem("userData", JSON.stringify(value));
    ReactGA.event({
      category: "User",
      action: "Login",
      label: "Authentication: " + value.googleId,
    });
    this.setState({ isLoading: false, authState: true, user: value });
  };

  login = (value) => {
    let profile = jwt_decode(value.credential);
    let saveProfile = INITIAL_USER_DATA;

    saveProfile.email = profile.email;
    saveProfile.familyName = profile.family_name;
    saveProfile.givenName = profile.given_name;
    saveProfile.googleId = profile.sub;
    saveProfile.imageUrl = profile.picture;
    saveProfile.name = profile.name;

    localStorage.setItem("userData", JSON.stringify(saveProfile));

    ReactGA.event({
      category: "User",
      action: "Login",
      label: "Authentication: " + profile.sub,
    });

    this.setState({
      isLoading: false,
      authState: true,
      user: saveProfile,
    });
  };

  logout = () => {
    googleLogout();
    localStorage.removeItem("userData");

    ReactGA.event({
      category: "User",
      action: "Logout",
      label: "Authentication: " + this.state.user.googleId,
    });

    this.setState({ isLoading: false, authState: false, user: {} });
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

const UserContextConsumer = UserContext.Consumer;

export { UserContextProvider, UserContextConsumer };
export default UserContext;
