import React from "react";
import PropTypes from "prop-types";

import Title from "./Title";
import Subtitle from "./Subtitle";

const Page = (props) => {
  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      <Subtitle>{props.Subtitle}</Subtitle>
      {props.children}
    </React.Fragment>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
