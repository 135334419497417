import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import Subtitle from "components/pages/Subtitle";

import Typography from "@mui/material/Typography";

import { UserContextConsumer } from "context/UserContext";

const NotAuthenticated = () => {
  let redirect = useLocation();

  return (
    <UserContextConsumer>
      {({ user, authState }) => {
        if (authState) {
          return <Navigate to={redirect.state.pathname} />;
        } else {
          return (
            <React.Fragment>
              <Typography variant="h4" align="center" color="secondary">
                You are not <b>Logged In!</b>
              </Typography>
              <Subtitle>
                Please log in with your GCC Google Account provided to you
                before viewing this page. If you are experiencing any issues
                logging in, please contact web@gracecovenant.net.
              </Subtitle>
            </React.Fragment>
          );
        }
      }}
    </UserContextConsumer>
  );
};

export default NotAuthenticated;
