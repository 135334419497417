import React from "react";

import { Routes, Route } from "react-router-dom";

import Home from "pages/home/Home";
import Dashboard from "pages/dashboard/Dashboard";
import Sermon from "pages/sermon/Sermon";
import Upload from "pages/sermon/Upload";
import Archive from "pages/sermon/Archive";

import PrivateRoute from "components/auth/PrivateRoute";
import NotAuthenticated from "components/auth/NotAuthenticated";

const BaseRouter = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<NotAuthenticated />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sermon" element={<Sermon />} />
          <Route path="/sermon/upload" element={<Upload />} />
          <Route path="/sermon/archive" element={<Archive />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default BaseRouter;
