import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

import NavigationBar from "components/nav/NavigationBar";

import BaseRouter from "pages/BaseRouter";

import { UserContextProvider } from "context/UserContext";
import GAInitialize from "analytics/GAInitialize";

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <UserContextProvider>
        <GAInitialize />
        <NavigationBar>
          <BaseRouter />
        </NavigationBar>
      </UserContextProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
