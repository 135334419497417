import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { default as MaterialCard } from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const Card = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <MaterialCard className={classes.card}>
        <CardMedia
          className={classes.cardMedia}
          image={props.image}
          title={props.heading}
        />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.heading}
          </Typography>
          <Typography>{props.description}</Typography>
        </CardContent>
        <CardActions>
          {props.url ? (
            <Button
              size="small"
              color="primary"
              component={Link}
              to={props.url}
            >
              Click Here
            </Button>
          ) : undefined}
        </CardActions>
      </MaterialCard>
    </React.Fragment>
  );
};

Card.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Card;
