import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { UserContextConsumer } from "context/UserContext";

const PrivateRoute = (props) => {
  // This is for refresh purposes
  let originalPath = useLocation();

  return (
    <UserContextConsumer>
      {({ user, authState, isLoading }) => {
        // Credit: https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
        return authState || isLoading ? (
          <Outlet />
        ) : (
          <Navigate state={originalPath} to="/login" />
        );
      }}
    </UserContextConsumer>
  );
};

export default PrivateRoute;
