import React from "react";
import ReactGA from "react-ga";

import { UserContextConsumer } from "context/UserContext";

import RouteChangeTracker from "./RouteChangeTracker";

const GAInitialize = () => {
  return (
    <React.Fragment>
      <UserContextConsumer>
        {({ user, authState }) => {
          ReactGA.initialize(
            process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
            {
              gaOptions: {
                userId: user.googleId ? user.googleId : "unauthenticated",
              },
            }
          );
        }}
      </UserContextConsumer>
      <RouteChangeTracker />
    </React.Fragment>
  );
};

export default GAInitialize;
