import React, { useEffect, useState } from "react";

import axios from "axios";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import Page from "components/pages/Page";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const Upload = (props) => {
  const classes = useStyles();
  const [cache, setCache] = useState();

  useEffect(() => {
    let api = process.env.REACT_APP_GCC_POSTGRES_API;
    const getCache = async () => {
      const cache = await axios.get(api + "cache").then((resp) => {
        return resp.data;
      });
      setCache(cache);
    };
    getCache();
  }, []);

  console.log(cache);

  return (
    <Page
      title="Upload a Sermon"
      subtitle="Please fill out the form below to upload a sermon."
    >
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              required
              id="sermonTitle"
              label="Sermon Title"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              required
              id="passage"
              label="Passage"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              required
              id="type"
              label="Service Type"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              required
              id="series"
              label="Series"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              required
              id="speaker"
              label="Speaker"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" component="label">
              Upload File
              <input type="file" hidden />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Upload;
