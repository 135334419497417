import React from "react";

import Title from "components/pages/Title";
import Subtitle from "components/pages/Subtitle";
import DisplayAccount from "components/auth/DisplayAccount";

const Welcome = (props) => {
  let { user } = props;

  return (
    <React.Fragment>
      <Title>GCC Portal</Title>
      <Subtitle>
        Welcome {user.givenName} {user.familyName} to Grace Covenant Church's
        internal portal page. If you have any questions or comments about the
        internal page, please reach out to web@gracecovenant.net
      </Subtitle>
      <hr className="my-2" />
      <DisplayAccount user={user} />
    </React.Fragment>
  );
};

export default Welcome;
