import React from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';

import Title from "components/pages/Title";
import Subtitle from "components/pages/Subtitle";
import Card from "components/card/Card";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const Archive = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>Archives</Title>
      <Subtitle>
        {
          "This page is to help support our Archive application. Please do not Archive if you do not know what you are Archiveing!"
        }
      </Subtitle>
      <Grid container spacing={2} justifyContent="center">
        <Grid item></Grid>
      </Grid>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              heading="Archive Archives"
              description="You have a recording of an old Archive? Please Archive it here!"
              image="https://www.volgacrc.org/editorArchives/images/FaithWebsites/church_mid_img1.jpg"
              url={"/Archive/Archive"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              heading="Archived Archives"
              description="Help us listen to old Archives that need to be tagged with the right information!"
              image="https://wp-media.patheos.com/blogs/sites/40/2018/08/aaron-burden-169739.png"
              url={"/Archive/archive"}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Archive;
