import React, { Component } from "react";
import { GoogleLogin } from "@react-oauth/google";

import Button from "@mui/material/Button";

import UserContext, { UserContextConsumer } from "context/UserContext";

class AuthButton extends Component {
  static contextType = UserContext;

  success = (response) => {
    this.context.login(response);
  };

  logout = () => {
    this.context.logout();
  };

  render() {
    return (
      <UserContextConsumer>
        {({ user, authState }) => {
          if (authState) {
            return (
              <Button color="inherit" onClick={this.logout}>
                Logout
              </Button>
            );
          } else {
            return (
              <GoogleLogin
                onSuccess={this.success}
                onError={() => {
                  console.log("Login Failed");
                }}
                auto_select
              />
            );
          }
        }}
      </UserContextConsumer>
    );
  }
}

export default AuthButton;
